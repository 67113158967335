import React from 'react'
import Layout from '../../../layout/Index'
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import Content from "../../../layout/content/Content";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from 'material-table';
import Head from '../../../layout/head/Head';
import BaseUrl from '../../../BaseURL/BaseUrl';
import { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
const Requestjion = () => {
    const [api,setapi]=useState();

    useEffect(()=>{
      try {
    const token = localStorage.getItem("accesstoken");
        var config = {
            method: "get",
            url: `${BaseUrl.baseurl}/admin/members`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          axios(config)
          .then((response)=>{
            console.log(response.data.data,"requestdata")
            setapi(response?.data?.data)
          })
          .catch((error)=>{
            console.log(error,"requesterroe")
          })
      } catch (error) {
        
      };
    },[])
 const deldata = (rowData) =>{
    try {
        const token = localStorage.getItem("accesstoken");
            var config = {
                method: "delete",
                url: `${BaseUrl.baseurl}/admin/members/${rowData.id}`,
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                },
              };
              axios(config)
              .then((response)=>{
                console.log(response,"requestdata")
                if(response.data.status === true){
                    Swal.fire({
                        title: "Deleted",
                        text: "Data Deleted Succesfully!",
                        icon: "delete",
                        buttons: true,
                        dangerMode: true,
                      })
                    window.location.reload();
                }
                // setData(response?.data?.data)
              })
              .catch((error)=>{
                console.log(error,"requesterroe")
              })
          } catch (error) {
            
          };
 }



  return (
     <Layout>
      <Head title="Request to join" />
      <Content>
    
          <MaterialTable
            icons={tableIcons}
            title="Request To Join"
            columns={[
              { title: "S.No", field: "id", cellStyle: { width: "6%" } },
              { title: "First Name", field: "fname" },
              { title: "last Name", field: "lname" },
              { title: "Email", field: "email" },
              { title: "Type", field: "type",cellStyle:{width:'10%'} },
              { title: "Interest", field: "interest" },
            ]}
            data={api}
            actions={[
              (rowData) => ({
                icon: () => <DeleteOutline className="text-theme" />,
                tooltip: "Delete User",
                onClick: (event, rowData) => deldata(rowData),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
 
      </Content>
   </Layout>
  );
};

export default Requestjion