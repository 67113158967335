/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Head from "../../../subadminlayout/head/Head";
import Content from "../../../subadminlayout/content/Content";
import Layout from "../../../subadminlayout/Index";
// import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import BaseUrl from "../../../BaseURL/BaseUrl";
import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, FormGroup, Row } from "react-bootstrap";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Profile = ({ sm, updateSm }) => {
  const classes = useStyles();

  const [modalTab, setModalTab] = useState("0");
  const [modal, setModal] = useState(false);
  const [, setData] = useState("");
  // const [id, setId] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [Email, setEmail] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setLoaders(false);
  };

  const open = () => {
    setModal(true);
    setModalTab("1");
  };
  const last = () => {
    setModal(true);
    setModalTab("2");
  };
  const email = () => {
    setModal(true);
    setModalTab("3");
  };
  console.log(modalTab, "modal===========");

  var token = localStorage.getItem("accesstoken");
  const id = localStorage.getItem("id");

  const viewData = () => {
    setLoaders(true);
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/subadmin/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoaders(false);
        console.log(response);
        setData(response?.data?.data);
        setFName(response?.data?.data?.fname);
        setLName(response?.data?.data?.lname);
        setEmail(response?.data?.data?.email);
        localStorage.setItem("fname", fname);
        localStorage.setItem("lname", lname);
        localStorage.setItem("email", Email);
      })
      .catch((error) => {
        setLoaders(false);
        console.log(error);
      });
  };

  useEffect(() => {
    viewData();
  }, []);

  const updateData = () => {
    setLoader(true);
    const data = new URLSearchParams();
    data.append("fname", fname);
    data.append("lname", lname);
    data.append("email", Email);

    var config = {
      method: "PUT",
      url: `${BaseUrl.baseurl}/subadmin/${id}`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false);
        console.log(response);
        if (response?.data?.status === true) {
          toast.success(response?.data?.response);
          setModal(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setModal(true);
        console.log(error);
      });
  };

  return (
    <Layout>
      <Head title="Sub Admin - Profile"></Head>
      <Content className="">
        {loaders === true ? (
          <Backdrop
            className={classes.backdrop}
            open={loaders}
            onClick={handleClose}
          >
            <CircularProgress color="red" />
          </Backdrop>
        ) : null}
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4" className="text-theme">
                Personal Information
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title ">Profile</h6>
            </div>
            <div className="data-item" onClick={open}>
              <div className="data-col">
                <h6 className="data-label">First Name</h6>
                <span className="data-value">{fname}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={last}>
              <div className="data-col">
                <h6 className="data-label">Last Name</h6>
                <span className="data-value">{lname}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={email}>
              <div className="data-col">
                <h6 className="data-label">Email</h6>
                <span className="data-value">{Email}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
          </div>
        </Block>
        <Modal
          isOpen={modal}
          className="modal-dialog-centered"
          size="lg"
          toggle={() => setModal(false)}
        >
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Profile</h5>

              {modalTab == "1" ? (
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label" htmlFor="First-name">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="First-name"
                            className="form-control"
                            name="name"
                            value={fname}
                            placeholder="Enter First name"
                            onChange={(e) => setFName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              className="btn-theme"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                              Update &nbsp;&nbsp;
                              {loader === true ? (
                                <CircularProgress
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "white",
                                  }}
                                />
                              ) : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : modalTab == "2" ? (
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label" htmlFor="Last-name">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="Last-name"
                            className="form-control"
                            name="name"
                            value={lname}
                            placeholder="Enter Last name"
                            onChange={(e) => setLName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              className="btn-theme"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                              Update &nbsp;&nbsp;
                              {loader === true ? (
                                <CircularProgress
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "white",
                                  }}
                                />
                              ) : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : modalTab == "3" ? (
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label" htmlFor="Email">
                            Email
                          </label>
                          <input
                            type="email"
                            id="Email"
                            className="form-control"
                            name="Email"
                            value={Email}
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              className="btn-theme"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                              Update &nbsp;&nbsp;
                              {loader === true ? (
                                <CircularProgress
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "white",
                                  }}
                                />
                              ) : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : null}
            </div>
          </ModalBody>
        </Modal>
     
      </Content>
    </Layout>
  );
};

export default Profile;
