/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
} from "@react-google-maps/api";
import { useEffect } from "react";
import { useRef } from "react";

const containerStyle = {
  width: "100%",
  height: "500px",
};


const AddDoctor = () => {
  const [FirstName, setFirstName] = useState("");
  const [Middle, setMiddle] = useState("");
  const [LastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [Unit, setUnit] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip_Code, setZip_Code] = useState("");
  const [Country, setCountry] = useState("");
  const [Years_in_Practice, setYears_in_Practice] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Education, setEducation] = useState("");
  const [Utilization_of_Hypnosis, setUtilization_of_Hypnosis] = useState("");
  const [Training, setTraining] = useState("");
  const [Certification, setCertification] = useState("");
  const [Email, setEmail] = useState("");
  const [Type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [PlaceName, setPlaceName] = useState();
  const [Profile, setProfile] = useState(null);
  const [ImageUrl, setImageUrl] = useState(null);
  const [Desc, setDesc] = useState("")
  const [Position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const history = useHistory();
  const token = localStorage.getItem("accesstoken");

  const handleimage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setProfile(file)
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }
  console.log(Profile,"Profile")

  const addapi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("fname", FirstName);
    data.append("mname", Middle);
    data.append("lname", LastName);
    data.append("email", Email);
    data.append("street_address", PlaceName ? PlaceName : Address);
    data.append("unit", Unit);
    data.append("city", City);
    data.append("state", State);
    data.append("zipcode", Zip_Code);
    data.append("country", Country);
    data.append("practice_years", Years_in_Practice);
    data.append("organization", Organization);
    data.append("education", Education);
    data.append("hypnosis_utilization", Utilization_of_Hypnosis);
    data.append("training", Training);
    data.append("certificate", Certification);
    data.append("type", Type);
    data.append("longitude", Position.lng);
    data.append("latitude", Position.lat);
    data.append("description", Desc);
        data.append("image", Profile);
    // data.append("role", "subadmin");

    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/admin/doctors`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");
        if (response.data.status == 1) {
          setLoader(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          history.push("/Practitioner");
        } else {
          alert("else");
          setLoader(false);
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.errors);
        setLoader(false);
      });
  };
  console.log(error, "error");

  //google map work

  const { isLoaded } = useJsApiLoader({
    // id: "google-map-script",
    googleMapsApiKey: "AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ",
    libraries: ["places"],
  });
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      // console.log(place.geometry.location.lat(),"place");
      // console.log(place.geometry.location.lng(),"place");
      setPosition({
        lat: Place.geometry.location.lat(),
        lng: Place.geometry.location.lng(),
      });
      // setPlaceName(place.formatted_address);
      setPlaceName(Place.name);
      console.log(Place, "place");
    });
  }, [Position]);
  console.log(Position.lat, "place");
  console.log(Position.lng, "place");
  console.log(PlaceName, "place");
  console.log(Position, "place");

  //google map work
  return (
    <>
      <Layout>
        <Head title="Add Practitioner" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-12">
                <div className="">
                  <h3 className="text-theme text-center py-2 ">Add Practitioner</h3>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <p className="text-theme">{error?.user_name}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label for="Middle">Middle Intial</label>
                      <input
                        type="text"
                        className="form-control"
                        id="Middle"
                        aria-describedby="emailHelp"
                        placeholder="Enter Middle Name"
                        onChange={(e) => setMiddle(e.target.value)}
                      />
                      <p className="text-theme">{error?.Middle}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <p className="text-theme">{error?.setLastName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="text-theme">{error?.Email}</p>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Type</label>
                      <select
                        aria-label="Default select example"
                        className="form-control"
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option>Select Practitioner Type</option>
                        <option value="doctor">doctor</option>
                        <option value="dentist">dentist</option>
                        <option value="hypnotherapist">hypnotherapist</option>
                      </select>
                      <p className="text-theme">{error?.Type}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <p className="text-theme">{error?.State}</p>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Unit</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Unit"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                      <p className="text-theme">{error?.Unit}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 mb-4"
                      style={{
                        display: "inline",
                        position: "relative",
                        zIndex: 1111,
                      }}
                    >
                      <label>Street Address</label>
                      <input
                        ref={inputRef}
                        // type="text"
                        className="form-control"
                        placeholder="Street Address"
                        // value={Adress}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="" style={{}}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Position.lat,
                        lng: Position.lng,
                      }}
                      zoom={10}
                      options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <MarkerF
                        position={{
                          lat: Position.lat,
                          lng: Position.lng,
                        }}
                      />

                      {/* <MarkerF
                      // key={index}
                        position={{
                          lat: Position[0]?.lat,
                          lng: Position[0]?.lng,
                        }}
                      /> */}
                    </GoogleMap>
                  </div>
                  <div className="row mt-3">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <p className="text-theme">{error?.password}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <p className="text-theme">{error?.Country}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Zip Code</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Zip Code"
                        onChange={(e) => setZip_Code(e.target.value)}
                      />
                      <p className="text-theme">{error?.Zip_Code}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Years in Practice</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Years in Practice"
                        onChange={(e) => setYears_in_Practice(e.target.value)}
                      />
                      <p className="text-theme">{error?.Years_in_Practice}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Organization</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Organization"
                        onChange={(e) => setOrganization(e.target.value)}
                      />
                      <p className="text-theme">{error?.Organization}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Education</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Education"
                        onChange={(e) => setEducation(e.target.value)}
                      />
                      <p className="text-theme">{error?.Education}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Utilization of Hypnosis</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Utilization of Hypnosis"
                        onChange={(e) =>
                          setUtilization_of_Hypnosis(e.target.value)
                        }
                      />
                      <p className="text-theme">
                        {error?.Utilization_of_Hypnosis}
                      </p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Training</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Training"
                        onChange={(e) => setTraining(e.target.value)}
                      />
                      <p className="text-theme">{error?.Training}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Certification</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Certification"
                        onChange={(e) => setCertification(e.target.value)}
                      />
                      <p className="text-theme">{error?.Certification}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Bio</label>
                      <textarea
                        placeholder="Enter Bio...."
                        rows={5}
                        cols={120}
                        className="p-3 form-control mb-2"
                        onChange={(e)=>setDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label>Profile Photo</label>
                      <input type="file" className="form-control mb-2" onChange={handleimage}/>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                    {ImageUrl && <img src={ImageUrl} alt="Selected Image" className="" width={50}/>}
                    </div>
                  </div>

                  <button
                    className="btn btn-theme w-25 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={addapi}
                  >
                    Add &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "white",
                        }}
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AddDoctor;
