import React from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import Layout from "../../../../layout/Index";
import { Card, Col, Row } from "reactstrap";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import { useState } from "react";

const Security = ({ sm, updateSm }) => {
    const [modal, setModal] = useState(false);
  return (
    <Layout>
      <Head title="User List - Profile"></Head>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4" className="text-danger">Security Settings</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Change Password</h6>
                    <p>Set a unique password to protect your account.</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Button color="danger" onClick={()=>setModal(true)}>Change Password</Button>
                      </li>
                      <li>
                        <em className="text-soft text-date fs-12px">
                          Last changed: <span>Oct 2, 2019</span>
                        </em>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Block>
        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Profile</h5>
              <ul className="nk-nav nav nav-tabs">
                
              </ul>
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          
                          <input
                            type="Password"
                            id="Password"
                            className="form-control"
                            name="old Password"
                            // onChange={(e) => onInputChange(e)}
                            placeholder="old Password"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          
                          <input
                            type="Password"
                            id="Password"
                            className="form-control"
                            name="New Password"
                            // onChange={(e) => onInputChange(e)}
                            placeholder="New Password"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          
                          <input
                            type="Password"
                            id="Password"
                            className="form-control"
                            name="Confirm Password"
                            // onChange={(e) => onInputChange(e)}
                            placeholder="Confirm Password"
                          />
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              color="danger"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                // submitForm();
                              }}
                            >
                              Update
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </Layout>
  );
};

export default Security;
