/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../subadminlayout/content/Content";
import Head from "../../../subadminlayout/head/Head";
import Layout from "../../../subadminlayout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const EditStudent = () => {
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
  
    const history = useHistory();
    const {id} = useParams();
    const token = localStorage.getItem("accesstoken");


    const editStd=()=>{
      setLoader(true)

      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}/subadmin/users/${id}`,
        headers:{
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
      }

      axios(config).then((response)=>{
        setLoader(false)
        console.log(response);
        const {fname,lname, email} = (response?.data?.data)
        setFName(fname)
        setLName(lname)
        setEmail(email)
      }).catch((error)=>{
        setLoader(false)
        console.log(error);
      })


    }

    useEffect(()=>{
      editStd();
    },[])


    const addapi = () => {
      setLoader(true);
      try {
        const data = new URLSearchParams();
        data.append("fname", fname);
        data.append("lname", lname);
        data.append("email", email);
  
        var config = {
          method: "PUT",
          url: `${BaseUrl.baseurl}/subadmin/users/${id}`,
          data: data,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        axios(config)
          .then(function (response) {
            console.log(response, "api");
            if (response?.data?.status === true) {
              setLoader(false);
              toast.success(response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              history.push("/Subadmin/user");
            } else {
              setLoader(false);
            }
          })
          .catch((errors) => {
            setLoader(false);
          });
      } catch (errors) {
        setLoader(false);
        console.log(errors, "error");
        toast.error(errors?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };
  return <>
    <Layout>
        <Head title="Add Users" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-6 boxsh ">
                <div className="card  px-2 py-4 rounded-4">
                  <h3 className="text-theme text-center py-2 ">Update Users</h3>
                  <div className="form-group">
                    <label for="exampleInputEmail1">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={fname}
                      onChange={(e) => setFName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={lname}
                      onChange={(e) => setLName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center" onClick={addapi}>
                    Update &nbsp;&nbsp;{loader === true ? <CircularProgress style={{height: '20px', width: '20px', color: 'white'}} /> : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
  </>;
};

export default EditStudent;
