/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import BaseUrl from "../../../BaseURL/BaseUrl";
import { useEffect } from "react";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { FaToggleOn } from "react-icons/fa";
import { toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Students = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loader, setLoaders] = useState(false);
  const [api, setApi] = useState([]);
  const user = () => {
    setLoaders(true);

    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/admin/users`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "user");
        setApi(response?.data?.data);
        console.log(response?.data?.data);

        setLoaders(false);
      })
      .catch(function (error) {
        setLoaders(false);
        console.log(error);
      });
  };


  const handleClose = () => {
    setLoaders(false);
  };

  useEffect(() => {
    user();
  }, []);

  //active/deactive api

  const active = (id) => {
    setLoaders(true);

    const token = localStorage.getItem("accesstoken");
    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/admin/users/update_status/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.data?.status === true) {
          setLoaders(false);
          toast.success(response?.data?.response, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          user();
        } else {
          setLoaders(false);
          toast.error("something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch(function (error) {
        setLoaders(false);

        console.log(error);
      });
  };

  const [, setData] = useState();
  const deldata = (rowData) => {
    setLoaders(true);
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "delete",
        url: `${BaseUrl.baseurl}/admin/users/${rowData.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        setData(response.data);
        if (response.data == 1) {
          setLoaders(false);
        
        Swal.fire({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this User!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            toast.success("successfully delete", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            user();
            
          } else {
          }
        });
      }
      });
    } catch (error) {
      setLoaders(false);
    }
  };


  return (
    <Layout>
      <Head title="Users" />
      <Content>
        {loader === true ? (
          <Backdrop className={classes.backdrop} open={loader} onClick={handleClose}>
            <CircularProgress color="red" />
          </Backdrop>
        ) : null}
          <MaterialTable
            icons={tableIcons}
            title="Users"
            columns={[
              { title: "S.No", field: "id", cellStyle: { width: "10%" } },
              { title: "First Name", field: "fname" },
              { title: "Last Name", field: "lname" },
              { title: "Email", field: "email" },
            ]}
            data={api}
            actions={[
              {
                icon: () => <AddBox className="text-theme" />,
                tooltip: "Add Users",
                isFreeAction: true,
                onClick: (event) => {
                  history.push("/adduser");
                },
              },
              (rowData) => ({
                icon: () => <FaToggleOn className="text-theme border-0" />,
                tooltip: "Active / Deactive",
                onClick: (event, rowData) => active(rowData.id),
              }),
              {
                icon: () => <Edit className="text-theme " />,
                tooltip: "Edit User",
                onClick: (event, rowData) => history.push("/edituser/" + rowData.id),
              },
              (rowData) => ({
                icon: () => <DeleteOutline className="text-theme" />,
                tooltip: "Delete User",
                onClick: (event, rowData) => deldata(rowData),
              }),
              // (rowData) => ({
              //   icon: () => <TiDocumentAdd className="text-theme" />,
              //   tooltip: "Assign User",
              //   onClick: (event, rowData) => handlemodal(rowData.id),
              // }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />

      </Content>
    </Layout>
  );
};

export default Students;
