/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Layout from "../../../layout/Index";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../../../pages/pages.css";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Doctor = () => {

    const classes = useStyles();
    const history = useHistory();
  
    const [loader, setLoaders] = useState(false);
    const [api, setApi] = useState([]);
    const DoctorApi = () => {
      setLoaders(true);
  
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}/admin/doctors`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      axios(config)
        .then(function (response) {
          console.log(response,"Doctor");
          setApi(response?.data?.data);
          setLoaders(false);
        })
        .catch(function (error) {
          setLoaders(false);
  
          console.log(error);
        });
    };
    console.log("subadminApi ", api);
  
    useEffect(() => {
      DoctorApi();
    }, []);
  
    const [, setData] = useState();
    const deldata = (rowData) => {
      setLoaders(true);
      try {
        const token = localStorage.getItem("accesstoken");
        var config = {
          method: "delete",
          url: `${BaseUrl.baseurl}/admin/doctors/${rowData.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        axios(config).then(function (response) {
          setData(response.data);
          console.log(response, "wworking");
          if (response.data == 1) {
            setLoaders(false);
          Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover Supreme Manager!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              toast.success("successfully delete", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              DoctorApi();
            
            } else {
            }
          
          });
        }
        });
      } catch (error) {
        setLoaders(false);
      }
    }; 
  
  
    return (
      <Layout>
        <Head title="Practitioner Dashboard" />
        <Content>
        {loader === true ? (
            <Backdrop className={classes.backdrop} open={loader} >
              <CircularProgress color="red" />
            </Backdrop>
          ) : null}
          <MaterialTable
            icons={tableIcons}
            title="Practitioner"
            columns={[
              { title: "S.No", field: "id", cellStyle: { width: "10%" },},
              { title: "Name", field: "fname",cellStyle: { width: "10%" } },
              { title: "Email", field: "email" },
              { title: "Practitioner Type", field: "type" },
              { title: "street_address", field: "street_address", cellStyle: { width: "20%" } },
            ]}
            data={api}
            actions={[
              {
                icon: () => <AddBox className="text-theme" />,
                tooltip: "Add Practitioner",
                isFreeAction: true,
                onClick: (event) => {
                  history.push("/addPractitioner");
                },
              },
              // (rowData) => ({
              //   icon: () => <FaToggleOn className="text-theme border-0" />,
              //   tooltip: "Active / Deactive",
              //   onClick: (event, rowData) =>active(rowData.id),
              // }),
              {
                icon: () => <Edit className="text-theme " />,
                tooltip: "Edit Doctor",
                onClick: (event, rowData) => history.push('/editPractitioner/' + rowData.id),
              },
              (rowData) => ({
                icon: () => <DeleteOutline className="text-theme" />,
                tooltip: "Delete Practitioner",
                onClick: (event, rowData) => deldata(rowData),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </Content>
      </Layout>
    );
  };
  
  export default Doctor;