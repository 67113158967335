const menu = [
  
    {
      icon: "users-fill",
      text: "Sub Admin",
      link: "/Subadmin/dashbaord",
    },
    {
      icon: "user-round",
      text: "Practitioner",
      link: "/Subadmin/Practitioner",
    },
    {
      icon: "user-list-fill",
      text: "User",
      link: "/Subadmin/user",
    },
    // {
    //   icon: "user-round",
    //   text: "Contact",
    //   link: "/contact",
    // },
]

;

export default menu;
