import React, { useState } from "react";
import Logo from "../../../images/logo2x.png";
import LogoDark from "../../../images/logo-dark2x.png";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../../components/Component";
import { Spinner, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../../utlilites/axios";

const Register = () => {
  const { query } = useParams();
  console.log(query, "param1Value");
  const [ConfirmPassState, setConfirmPassState] = useState(false);
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    ref_code: Yup.string(),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
      password_confirmation: Yup.string().oneOf([Yup.ref("password"),null],"password and confirmation password not same"), 
  });

  const history = useHistory();
  const Register = async (V) => {
    setLoading(true);
    try {
      const response = await axios.post("user/signup", V);
      setLoading(false);
      console.log(response, "working");
      const { message, status } = response.data;
      if (status === true) {
        localStorage.setItem("Refral_link", response?.data?.user?.reference_link);
        localStorage.setItem("Refral_code", response?.data?.user?.reference_code);
        localStorage.setItem("user_name", response?.data?.user?.name);
        localStorage.setItem("email", response?.data?.user?.email);
        setLoading(false);
        Swal.fire({
          title: "Good job!",
          text: "Register SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        history.push("/");
      }
      // setLoader(false);
      else {
        setLoading(false);
        Swal.fire({
          title: "OOps!",
          text: message,
          icon: "danger",
          button: "Ok",
        });
      }
    } catch (error) {
      setLoading(false);
      // setLoader(false);
      console.log(error?.response?.message);
      Swal.fire({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
        confirmButtonText: "ok",
      });
    }
  };
  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4" className="text-danger">
                  Register
                </BlockTitle>
                <BlockDes>
                  <p>Create New Account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                ref_code: query ? query : "",
                role: "user",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // same shape as initial values
                Register(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <FormGroup>
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <div className="form-control-wrap">
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        className="form-control-lg form-control"
                      />
                      {errors.name && touched.name ? <div className="text-danger">{errors.name}</div> : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Email
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <Field
                        name="email"
                        type="email"
                        className="col-lg-12 form-control-lg form-control mb-3"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                      </a>
                      <Field
                        type={passState ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="Confirmation">
                        confirm Password
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password_confirmation"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setConfirmPassState(!ConfirmPassState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          ConfirmPassState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                      </a>
                      <Field
                        type={ConfirmPassState ? "text" : "password"}
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="Enter your password"
                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                      />
                      {errors.password_confirmation && touched.password_confirmation ? (
                        <div className="text-danger">{errors.password_confirmation}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="Confirmation">
                        Refral Code
                      </label>
                    </div>
                    <Field name="ref_code" type="text" className="form-control" placeholder="Refral Code" value={query}/>

                    {errors.ref_code && touched.ref_code ? <div className="text-danger">{errors.ref_code}</div> : null}
                  </FormGroup>

                  <FormGroup>
                    <Button type="submit" color="primary" size="lg" className="btn-block btn-danger">
                      {loading ? <Spinner size="sm" color="light" /> : "Register"}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Already have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <strong className="text-danger">Sign in instead</strong>
              </Link>
            </div>
            {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-8">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Google
                </a>
              </li>
            </ul> */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
