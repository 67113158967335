/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Layout from "../../../layout/Index";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import Content from "../../../layout/content/Content";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import Head from "../../../layout/head/Head";
import BaseUrl from "../../../BaseURL/BaseUrl";
import { forwardRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Podcast = () => {
  const [data, setdata] = useState();
  const [loader, setLoaders] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const token = localStorage.getItem("accesstoken");

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/admin/podcasts`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((responce) => {
        console.log(responce);
        setdata(responce?.data?.data);
        setLoaders(false);
      })
      .catch((error) => {
        setLoaders(false);
        console.log(error);
      });
  }, []);
  //   console.log(data[1].video,'adsdasd')
  const handleClose = () => {
    setLoaders(false);
  };

  const deldata = (rowData) => {
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "delete",
        url: `${BaseUrl.baseurl}/admin/podcasts/${rowData.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        setdata(response.data);
        console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Podcast!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              toast.success("successfully delete", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              window.location.reload();
            } else {
            }
          });
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <Layout>
        <Head title="Podcast" />
        {loader === true ? (
          <Backdrop
            className={classes.backdrop}
            open={loader}
            onClick={handleClose}
          >
            <CircularProgress color="red" />
          </Backdrop>
        ) : null}
        <Content>
          <MaterialTable
            icons={tableIcons}
            title="Podcast"
            columns={[
              { title: "S.No", field: "id", cellStyle: { width: "10%" } },
              { title: "Title", field: "title" },
              { title: "Date", field: "date" },
              //   { title: "Podcast Video", field: "video" },
              {
                title: "Podcast Video",
                field: "video",
                render: (item) => {
                  return item.video !== "" ? (
                    <>
                      <video
                        src={item.video}
                        alt=""
                        border="3"
                        height="70"
                        width="100"
                        autoPlay
                        muted
                        loop
                        playsInline
                      >
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <>
                      <iframe
                        src={item.link}
                        title="youtube"
                        alt=""
                        border="3"
                        height="70"
                        width="100"
                      ></iframe>
                    </>
                  );
                },
                cellStyle: { width: "20%" },
              },
            ]}
            data={data}
            actions={[
              {
                icon: () => <AddBox className="text-theme" />,
                tooltip: "Add Users",
                isFreeAction: true,
                onClick: (event) => {
                  history.push("/AddPodcast");
                },
              },
              //   (rowData) => ({
              //     icon: () => <Edit className="text-theme " />,
              //     tooltip: "Edit User",
              //     onClick: (event, rowData) =>
              //       history.push("/UpdatePod/" + rowData.id),
              //   }),
              (rowData) => ({
                icon: () => <DeleteOutline className="text-theme" />,
                tooltip: "Delete User",
                onClick: (event, rowData) => deldata(rowData),
              }),
              // (rowData) => ({
              //   icon: () => <TiDocumentAdd className="text-theme" />,
              //   tooltip: "Assign User",
              //   onClick: (event, rowData) => handlemodal(rowData.id),
              // }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </Content>
      </Layout>
    </>
  );
};

export default Podcast;
