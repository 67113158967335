/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Layout from "../../../layout/Index";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import BaseUrl from "../../../BaseURL/BaseUrl";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import './Editor.css'
import 'react-quill/dist/quill.snow.css';



const Research = () => {
  const [data, setdata] = useState([]);
  const [, setLoaders] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem("accesstoken");

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/admin/researches`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((responce) => {
        console.log(responce, "show api");
        setdata(responce?.data?.data);
        setLoaders(false);
      })
      .catch((error) => {
        setLoaders(false);
        console.log(error);
      });
  }, []);

  
  const deldata = (id) => {
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "delete",
        url: `${BaseUrl.baseurl}/admin/researches/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Podcast!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              toast.success("successfully delete", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              window.location.reload();
            } else {
            }
          });
        }
      });
    } catch (error) {}
  };



  return (
    <Layout>
      <Head title="Research" />
    
      <Content>
        <div className="d-flex justify-content-end align-items-center mb-3">
            <button className="bg-theme p-2 w-25 rounded border-0" onClick={()=>history.push('/addresearch')}>Add</button>
        </div>
        {data?.map((e) => {
          return (
            <div className="card p-3 shadow">
              <div className="">
                <h4 className="text-center">Content</h4>
                <hr />
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: e?.description }}></div>
                <hr />
                <div className="d-flex justify-content-around align-items-center">
                  <button className="bg-theme p-2 w-25 rounded border-0"  onClick= {() => history.push("/editresearch/" + e?.id)}>
                    Edit
                  </button>
                  <button
                    className="bg-danger p-2 w-25 rounded border-0"
                    onClick={() => deldata(e?.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Content>
    </Layout>
  );
};

export default Research;
