import React,{useState} from 'react'
import Layout from "../../../layout/Index";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import BaseUrl from "../../../BaseURL/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress } from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Add = ({onContentChange}) => {

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': ['justify','center','right'] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    ['link', 'image'],
    ['clean']
  ];

  
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const [error, setError] = useState("");
    const [value, setValue] = useState('');

    

     const addapi = () => {
       const data = new FormData();
       data.append("description", value);
       console.log(value);
       const token = localStorage.getItem("accesstoken");
       setLoader(true);
  
       var config = {
         method: "POST",
         url: `${BaseUrl.baseurl}/admin/researches`,
         data: data,
         headers: {
           Accept: "application/json",
           Authorization: `Bearer ${token}`,
         },
       };
       axios(config)
         .then((response) => {
           console.log(response, "api");
           if (response.data.status === true) {
             setLoader(false);
             toast.success("Podcast Add Successful", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
             });
             history.push("/research");
           } else {
             setLoader(false);
           }
           console.log(response);
         })
         .catch((errors) => {
           console.log(errors);
           setError(errors?.response?.data?.errors);
           setLoader(false);
         });
     };

  return (
    <Layout>
    <Head title="Add Research" />
    <Content>
      <div className="container-fluid card rounded boxsh py-3">
        <div className="row justify-content-center p-3">
          <div className="col-md-10 boxsh ">
            <div className="card  px-2 py-4 rounded-4">
              <h3 className="text-theme text-center py-2 ">Add Research</h3>
              <div className="form-group">
                <label for="exampleInputEmail1">Title</label>
                <ReactQuill theme="snow" value={value} onChange={setValue}  modules={{ toolbar: toolbarOptions }}/> 
                <p className="text-danger">{error?.title}</p>
              </div>
           
              <button
                className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center"
                onClick={addapi}
              >
                Add &nbsp;&nbsp;
                {loader === true ? (
                  <CircularProgress
                    style={{ height: "20px", width: "20px", color: "white" }}
                  />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Layout>
  )
}

export default Add