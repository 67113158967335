/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
} from "@react-google-maps/api";
import { useRef } from "react";

const containerStyle = {
  width: "100%",
  height: "500px",
};



const EditDoctor = () => {
  const [FirstName, setFirstName] = useState("");
  const [Middle, setMiddle] = useState("");
  const [LastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [Unit, setUnit] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip_Code, setZip_Code] = useState("");
  const [Country, setCountry] = useState("");
  const [Years_in_Practice, setYears_in_Practice] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Education, setEducation] = useState("");
  const [Utilization_of_Hypnosis, setUtilization_of_Hypnosis] = useState("");
  const [Training, setTraining] = useState("");
  const [Certification, setCertification] = useState("");
  const [Email, setEmail] = useState("");
  const [Type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [PlaceName, setPlaceName] = useState();
  const [Profile, setProfile] = useState(null);
  // const [ImageUrl, setImageUrl] = useState(null)
  const [Desc, setDesc] = useState("")
  const [image, setImage] = useState()
  const [Position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const handleimage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setProfile(file)
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  }

  console.log(Profile,"Profile")
  
    const history = useHistory();
    const {id} = useParams();
    const token = localStorage.getItem("accesstoken");


    const ShowDoctor=()=>{
      setLoader(true)
      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}/admin/doctors/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then((response)=>{
        setLoader(false)
        console.log(response);
        const {lname,mname,email,certificate,city,country,education,fname,hypnosis_utilization,organization,practice_years,state,street_address,training,type,unit,zipcode,latitude,longitude,description,image} = (response?.data?.data)
        setFirstName(fname)
        setMiddle(mname)
        setLastName(lname)
        setEmail(email)
        setAddress(street_address)
        setUnit(unit)
        setCity(city)
        setCountry(country)
        setState(state)
        setZip_Code(zipcode)
        setTraining(training)
        setType(type)
        setYears_in_Practice(practice_years)
        setUtilization_of_Hypnosis(hypnosis_utilization)
        setEducation(education)
        setCertification(certificate)
        setOrganization(organization)
        setDesc(description)
        setImage(image)
        setPosition({lat: parseFloat(latitude),lng: parseFloat(longitude)})
      }).catch((error)=>{
        setLoader(false)
        console.log(error);
      })

    }

    useEffect(()=>{
      ShowDoctor();
    },[])
    

//google map work

const { isLoaded } = useJsApiLoader({
  // id: "google-map-script",
  googleMapsApiKey: "AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ",
  libraries: ["places"],
});
const autoCompleteRef = useRef();
const inputRef = useRef();

useEffect(() => {
  console.log("useeffect", +1);
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    inputRef.current
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
    const Place = await autoCompleteRef.current.getPlace();
    // console.log(place.geometry.location.lat(),"place");
    // console.log(place.geometry.location.lng(),"place");
    setPosition({
      lat: Place.geometry.location.lat(),
      lng: Place.geometry.location.lng(),
    });
    // setPlaceName(place.formatted_address);
    setPlaceName(Place.name);
    console.log(Place, "place");
  });
}, [Position]);
console.log(Position.lat, "place");
console.log(Position.lng, "place");
console.log(PlaceName, "place");
console.log(Position, "place");

//google map work



    const editapi = () => {
      setLoader(true);
      try {
        var data = new URLSearchParams();
        data.append("fname", FirstName);
        data.append("mname", Middle);
        data.append("lname", LastName);
        data.append("email", Email);
        data.append("street_address", PlaceName ? PlaceName : Address);
        data.append("unit", Unit);
        data.append("city", City);
        data.append("state", State);
        data.append("zipcode", Zip_Code);
        data.append("country", Country);
        data.append("practice_years", Years_in_Practice);
        data.append("organization", Organization);
        data.append("education", Education);
        data.append("hypnosis_utilization", Utilization_of_Hypnosis);
        data.append("training", Training);
        data.append("certificate", Certification);
        data.append("type", Type);
        data.append("longitude", Position.lng);
        data.append("latitude", Position.lat);
        data.append("description", Desc);
        // data.append("image", Profile);
  
        var config = {
          method: "PUT",
          url: `${BaseUrl.baseurl}/admin/doctors/${id}`,
          data: data,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        axios(config)
          .then(function (response) {
            console.log(response, "api");
            if (response.data.status == 1) {
              setLoader(false);
              toast.success("update Practitioner", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              history.push("/Practitioner");
            } else {
              setLoader(false);    
              toast.error(response?.data?.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          })
          .catch((errors) => {
            setLoader(false);
            setError(errors?.response?.data?.error);
          });
      } catch (errors) {
        setLoader(false);
        console.log(errors, "error");
        setError(errors?.response?.data?.error);
        toast.error(errors?.response?.data?.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };


  const EditImage = () => {
      setLoader(true);
      try {
        var data = new FormData();
        data.append("image", Profile);
  
        var config = {
          method: "POST",
          url: `${BaseUrl.baseurl}/admin/doctors/updateMedia/${id}`,
          data: data,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        axios(config)
          .then(function (response) {
            console.log(response, "api");
            if (response.data.status === true) {
              setLoader(false);
              editapi()
            } else {
              setLoader(false);    
              toast.error(response?.data?.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          })
          .catch((errors) => {
            setLoader(false);
            setError(errors?.response?.data?.error);
          });
      } catch (errors) {
        setLoader(false);
        console.log(errors, "error");
        setError(errors?.response?.data?.error);
        toast.error(errors?.response?.data?.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };


  return (
  <>
    <Layout>
        <Head title="Update Doctor" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-12">
                <div className="">
                  <h3 className="text-theme text-center py-2 ">Update Doctor</h3>
                  <div className="row">
                  <div className="form-group col-md-4">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={FirstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <p className="text-theme">{error?.user_name}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label for="Middle">Middle Intial</label>
                    <input
                      type="text"
                      className="form-control"
                      id="Middle"
                      aria-describedby="emailHelp"
                      placeholder="Enter Middle Name"
                      value={Middle}
                      onChange={(e) => setMiddle(e.target.value)}
                    />
                    <p className="text-theme">{error?.Middle}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={LastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <p className="text-theme">{error?.setLastName}</p>
                  </div>
                  </div>
                    <div className="row">
                    <div className="form-group col-md-4">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="text-theme">{error?.Email}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Type</label>
                      <select aria-label="Default select example" className="form-control" value={Type} onChange={(e)=>setType(e.target.value)}>
                        <option>Select Doctor Type</option>
                        <option value="docotr">docotr</option>
                        <option value="dentist">dentist</option>
                        <option value="hypnotherapist">hypnotherapist</option>
                      </select>
                      <p className="text-theme">{error?.Type}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <p className="text-theme">{error?.State}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-8">
                      <label>Street Address</label>
                      <input
                        type="text"
                        ref={inputRef}
                        className="form-control"
                        placeholder="Street Address"
                        // value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <p className="text-theme">{error?.Address}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Unit</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Unit"
                        value={Unit}
                        onChange={(e) => setUnit(e.target.value)}
                      />
                      <p className="text-theme">{error?.Unit}</p>
                    </div>
                  </div>
                  <div className="" style={{}}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat:Position.lat,
                          lng: Position.lng
                      }}
                      zoom={10}
                      options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <MarkerF
                        position={{
                          lat:Position.lat,
                          lng: Position.lng
                        }}
                      />

                      {/* <MarkerF
                      // key={index}
                        position={{
                          lat: Position[0]?.lat,
                          lng: Position[0]?.lng,
                        }}
                      /> */}
                    </GoogleMap>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <p className="text-theme">{error?.password}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        value={Country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <p className="text-theme">{error?.Country}</p>
                    </div>
                    <div className="form-group col-md-4">
                      <label>Zip Code</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Zip Code"
                        value={Zip_Code}
                        onChange={(e) => setZip_Code(e.target.value)}
                      />
                      <p className="text-theme">{error?.Zip_Code}</p>
                    </div>
                  </div>
                    <div className="row">
                 
                  <div className="form-group col-md-4">
                    <label>Years in Practice</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Years in Practice"
                      value={Years_in_Practice}
                      onChange={(e) => setYears_in_Practice(e.target.value)}
                    />
                    <p className="text-theme">{error?.Years_in_Practice}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Organization</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Organization"
                      value={Organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                    <p className="text-theme">{error?.Organization }</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Education</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Education"
                      value={Education}
                      onChange={(e) => setEducation(e.target.value)}
                    />
                    <p className="text-theme">{error?.Education}</p>
                  </div>
                    </div>
                    <div className="row">
                   
                    <div className="form-group col-md-4">
                    <label>Utilization of Hypnosis</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Utilization of Hypnosis"
                      value={Utilization_of_Hypnosis}
                      onChange={(e) => setUtilization_of_Hypnosis(e.target.value)}
                    />
                    <p className="text-theme">{error?.Utilization_of_Hypnosis}</p>
                  </div>
                    <div className="form-group col-md-4">
                    <label>Training</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Training"
                      value={Training}
                      onChange={(e) => setTraining(e.target.value)}
                    />
                    <p className="text-theme">{error?.Training}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Certification</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Certification"
                      value={Certification}
                      onChange={(e) => setCertification(e.target.value)}
                    />
                    <p className="text-theme">{error?.Certification}</p>
                  </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                      <label>Bio</label>
                      <textarea
                        placeholder="Enter Bio...."
                        rows={5}
                        cols={120}
                        className="p-3 form-control mb-2"
                        value={Desc}
                        onChange={(e)=>setDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label>Profile Photo</label>
                      <input type="file" className="form-control mb-2" onChange={handleimage}/>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                    {image && <img src={image} alt="Selected Image" className="" width={50}/>}
                    </div>
                  </div>
                  <button
                    className="btn btn-theme w-25 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={EditImage}
                  >
                    Update &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress style={{ height: "20px", width: "20px", color: "white" }} />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
  </>
  )
}

export default EditDoctor