/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Edit = () => {


    const [loader, setLoader] = useState(false);
    const [value, setValue] = useState('');
 
  
    const history = useHistory();
    const {id} = useParams();
    const token = localStorage.getItem("accesstoken");


    const editStd=()=>{
      setLoader(true)

      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}/admin/researches/${id}`,
        headers:{
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
      }

      axios(config).then((response)=>{
        setLoader(false)
        console.log(response);
        const {description} = (response?.data?.data);
        setValue(description);
      }).catch((error)=>{
        setLoader(false)
        console.log(error);
      })


    }

    useEffect(()=>{
      editStd();
    },[])


    const addapi = () => {
      setLoader(true);
      try {
        const data = new URLSearchParams();
        data.append("description", value);
  
        var config = {
          method: "PUT",
          url: `${BaseUrl.baseurl}/admin/researches/${id}`,
          data: data,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        axios(config)
          .then(function (response) {
            console.log(response, "api");
            if (response?.data?.status === true) {
              setLoader(false);
              toast.success(response?.data?.response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              history.push("/research");
            } else {
              setLoader(false);
            }
          })
          .catch((errors) => {
            setLoader(false);
          });
      } catch (errors) {
        setLoader(false);
        console.log(errors, "error");
        toast.error(errors?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    };


  return (
    <Layout>
    <Head title="Edit Research" />
    <Content>
      <div className="container-fluid card rounded boxsh py-3">
        <div className="row justify-content-center p-3">
        <div className="col-md-10 boxsh ">
            <div className="card  px-2 py-4 rounded-4">
              <h3 className="text-theme text-center py-2 ">Edit Research</h3>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <ReactQuill theme="snow" value={value} onChange={setValue} /> 
              </div>
           
              <button
                className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center"
                onClick={addapi}
              >
                Update &nbsp;&nbsp;
                {loader === true ? (
                  <CircularProgress
                    style={{ height: "20px", width: "20px", color: "white" }}
                  />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </Layout>
  )
}

export default Edit