import React, { useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import Layout from "../../../../layout/Index";
// import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
// import BaseUrl from "../../BaseURL/BaseUrl";
// import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import Swal from "sweetalert2";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Profile = ({ sm, updateSm }) => {
  const classes = useStyles();

  // const [modalTab, setModalTab] = useState("0");
  const [modal, setModal] = useState(false);
  // const [data, setData] = useState("");
  // const [id, setId] = useState("");
  // const [name, setName] = useState("");
  // const [Email, setEmail] = useState("");
  const [loaders, setLoaders] = useState(false);
  // const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setLoaders(false);
  };

  // const open = () => {
  //   setModal(true);
  //   setModalTab("1");
  // };
  // const email = () => {
  //   setModal(true);
  //   setModalTab("2");
  // };
  // console.log(modalTab, "modal===========");

  // var token = localStorage.getItem("accesstoken");
  const name = localStorage.getItem("user_name");
  const email = localStorage.getItem("email");

  // const viewData = () => {
  //   setLoaders(true);
  //   var config = {
  //     method: "get",
  //     url: `${BaseUrl.baseurl}view`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then((response) => {
  //       setLoaders(false);
  //       console.log(response);
  //       setData(response?.data?.data);
  //       setId(response?.data?.data?.id);
  //       setName(response?.data?.data?.name);
  //       setEmail(response?.data?.data?.email);
  //     })
  //     .catch((error) => {
  //       setLoaders(false);
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   viewData();
  // }, []);

  // const updateData = () => {
  //   setLoader(true)
  //   const data = new FormData();
  //   data.append('name',name);
  //   data.append('email',Email);

  //   var config = {
  //     method: "post",
  //     url: `${BaseUrl.baseurl}profile/update/${id}`,
  //     data: data,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //   .then((response) => {
  //       setLoader(false)
  //       console.log(response);
  //       if(response?.data?.status === true){
  //        toast.success(response?.data?.message);
  //         setModal(false)
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false)
  //       setModal(true)
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      <Head title="User List - Profile"></Head>
      <Content className="">
        {loaders === true ? (
          <Backdrop className={classes.backdrop} open={loaders} onClick={handleClose}>
            <CircularProgress color="red" />
          </Backdrop>
        ) : null}
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4" className="text-danger">
                Personal Information
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title ">Basics</h6>
            </div>
            <div className="data-item"
            // onClick={open}
            >
              <div className="data-col">
                <h6 className="data-label">Full Name</h6>
                <span className="data-value">{name}</span>
              </div>
              {/* <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div> */}
            </div>
            <div className="data-item" 
            // onClick={email}
            >
              <div className="data-col">
                <h6 className="data-label">Email</h6>
                <span className="data-value">{email}</span>
              </div>
              {/* <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div> */}
            </div>
          </div>
        </Block>
        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Profile</h5>

              {/* {modalTab == "1" ? (
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label" htmlFor="full-name">
                            Full Name
                          </label>
                          <input
                            type="text"
                            id="full-name"
                            className="form-control"
                            name="name"
                            value={name}
                            placeholder="Enter Full name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              color="danger"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                                Update &nbsp;&nbsp;{loader === true ? <CircularProgress style={{height: '20px', width: '20px', color: 'white'}} /> : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : modalTab == "2" ? (
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label" htmlFor="Email">
                            Email
                          </label>
                          <input
                            type="email"
                            id="Email"
                            className="form-control"
                            name="Email"
                            value={Email}
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              color="danger"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                               Update &nbsp;&nbsp;{loader === true ? <CircularProgress style={{height: '20px', width: '20px', color: 'white'}} /> : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : null} */}
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </Layout>
  );
};

export default Profile;
