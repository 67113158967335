/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Head from "../../subadminlayout/head/Head";
import Content from "../../subadminlayout/content/Content";
import Layout from "../../subadminlayout/Index";
import { Icon, LineChartExample, PreviewCard } from "../../components/Component";
import { solidLineChart } from "../components/charts/ChartData";
import { Link } from "react-router-dom";
import Card from "../components/Cards/Card";

const SubAdminHome = () => {
  return (
    <Layout>
      <Head title="Dashboard"></Head>
      <Content>
        <div className="row p-3">
  
              {Card[1].map((e) => {
                return (
                  <>
                    <Link to={e.link} className="col-md-3">
                      <div>
                        <div className="card  px-2 py-4 rounded-4  cardsty">
                          <div className="border  flex-direction-column justify-content-center p-4 rounded ">
                            <Icon name={e.icon} className="h1 card_icon" />
                            <h5 className="text-theme text-center py-2 ">{e.text}</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
        </div>
        <PreviewCard>
          <div className="nk-ck">
            <LineChartExample legend={true} data={solidLineChart} />
          </div>
        </PreviewCard>

      </Content>
    </Layout>
  );
};
export default SubAdminHome;
