/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import BaseUrl from "../../../../BaseURL/BaseUrl";

import Icon from "../../../../components/icon/Icon";
// import data from "./NotificationData";

// const NotificationItem = (props) => {
//   const { icon, iconStyle, text, time, id } = props;
//   return (
//     <div className="nk-notification-item" key={id} id={id}>
//       <div className="nk-notification-icon">
//         <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
//       </div>
//       <div className="nk-notification-content">
//         <div className="nk-notification-text">{text}</div>
//         <div className="nk-notification-time">{time}</div>
//       </div>
//     </div>
//   );
// };

const Notification = () => {

  const [,setLoaders] = useState();
  const [Data,setData] = useState();
  const token = localStorage.getItem("accesstoken")
  const id = localStorage.getItem("user_id")
  
  
   const viewData = () => {
      setLoaders(true);
      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}user/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      axios(config)
        .then((response) => {
          setLoaders(false);
          console.log(response);
          setData(response?.data?.user);
        })
        .catch((error) => {
          setLoaders(false);
          console.log(error);
        });
    };
  
    useEffect(() => {
      viewData();
    }, []);
  

  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-info">
          <Icon name="wallet" />
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xl dropdown-menu-s1 border border-top border-danger">
        <div className="dropdown-head">
          <h4 className="w-100 text-danger text-center">Your wallet</h4>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            <div class=" my-3 w-75 mx-auto">
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="m-0">Total Earning </h6>
                <h6 class="">{Data?.receive_balance}</h6>
              </div>
            </div>
            <div class="  my-3 w-75 mx-auto">
              <div class=" d-flex justify-content-between align-items-center">
                <h6 class=" m-0">Current Balance </h6>
                <h6 class="">{Data?.current_balance}</h6>
              </div>
            </div>
            <div class="  my-3 w-75 mx-auto">
              <div class=" d-flex justify-content-between align-items-center">
                <h6 class=" m-0">Refaral Balance </h6>
                <h6 class="">{Data?.profit_balance}</h6>
              </div>
            </div>
            <div class="  my-3 w-75 mx-auto">
              <div class=" d-flex justify-content-between align-items-center">
                <h6 class=" m-0">Deduct Balance </h6>
                <h6 class="">{Data?.loss_balance}</h6>
              </div>
              {Data?.loss_balance !== 0 ?
              <p className="text-muted">Because you use the refral link for signup</p>
              :
              <p className="text-muted">Because you don't use the refral link for signup</p>
}
            </div>
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;
