import React, { useState } from "react";
import Logo from "../../../images/logo2x.png";
import LogoDark from "../../../images/logo-dark2x.png";
import PageContainer from "../../../subadminlayout/page-container/PageContainer";
import Head from "../../../subadminlayout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { setSession } from "../../../utlilites/jwt";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../utlilites/axios";

const Login = () => {
  const [loading, setLoading] = useState(false);

  // const setURl = () => {
  //   history.push("/register/402442");
  // };

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const history = useHistory();
  const landlogin = async (data) => {
    setLoading(true);
    try {
      const formData =new FormData()
      formData.append("email", data.email)
      formData.append("password", data.password) 

      
      const response = await axios.post("/subadmin/login", formData);
      setLoading(false);
      console.log(response, "working");
      const { status, access_token, fname, lname, id, email} = response.data.data;
      localStorage.setItem("id" ,id)
      localStorage.setItem("status" ,status)
      localStorage.setItem("fname" ,fname)
      localStorage.setItem("lname" ,lname)
      localStorage.setItem("email" ,email)
      // localStorage.setItem("user_id" ,response?.data?.data?.user?.id)
      // localStorage.setItem("is_login" ,response?.data?.data?.is_login)
      if (status === 1) {
        setLoading(false);
        setSession(access_token);
        Swal.fire({
          title: "Good job!",
          text: "Login SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        history.push('/Subadmin/dashbaord')
        }
        // setLoader(false);
       else {
        setLoading(false);
        Swal.fire({
          title: "OOps!",
          text: "message",
          icon: "danger",
          button: "Ok",
        });
      }
    } catch (error) {
      setLoading(false);
      // setLoader(false);
      console.log(error?.response?.message);
      Swal.fire({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Sub Admin Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg_login" src={Logo} alt="logo" />
              <img className="logo-dark logo-img_login logo-img-lg_login" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4" className="text-theme">Sign-In</BlockTitle>
                <BlockDes>
                  <h4>GAFCH</h4>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          // same shape as initial values
          landlogin(values);
        }}
      >
        {({ errors, touched }) => (
          <div className="container-fluid ">
            <Form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card ">
                    {/* <div className="card-header">
                      <h4 className="fw-bolder text-center">
                        Login to your Profile
                      </h4>
                    </div> */}
                    <div className="card-body">
                      <label className="form-label fw-bolder fs-6 ">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="col-lg-12 form-control mb-3"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                      <label className="form-label fw-bolder fs-6 ">
                        Password
                      </label>
                      <Field
                        name="password"
                        type="password"
                        className="col-lg-12 form-control"
                        placeholder="Enter Your Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <button
                        type="submit"
                        className="btn btn-theme mt-1  mx-auto text-center"
                      >
                        Login &nbsp;&nbsp;
                         {loading === true ? (
                          <CircularProgress style={{ height: "20px", width: "20px", color: "white" }} />
                        ) : null}
                      </button>
                      </div>

                      {/* <div className="col-lg-12 text-center">
                        <p className="text-light mt-2">
                          <Link to="/register" className="text-theme">
                            Sign Up as User
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
