import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Admin/auth/Login";
import SubAdminLogin from "./pages/Subadmin/auth/Login";
import Register from "./pages/Admin/auth/Register";
import Homepage from "./pages/Homepage";
import SubadminHome from "./pages/Subadmin/SubAdminHome";
import CryptoHomePage from "./pages/Admin/Subadmin/Subadmin";
import AddSubAdmin from "./pages/Admin/Subadmin/AddSubAdmin";
import User from "./pages/Admin/User/User";
import Requestjion from "./pages/Admin/requestJion/Requestjion";
import Podcast from "./pages/Admin/podcast/Podcast";
import SubAdminUser from "./pages/Subadmin/User/User";
import AddUser from "./pages/Admin/User/Adduser";
import ChartPage from "./pages/components/charts/Charts";
import EditSumAdmin from "./pages/Admin/Subadmin/EditSumAdmin";
import EditUser from "./pages/Admin/User/Edituser";
import Profile from "./pages/Admin/User/UserProfile/Profile";
import Security from "./pages/Admin/User/UserProfile/Security";
import SubadminProfile from "./pages/Subadmin/UserProfile/Profile";
import SubadminSecurity from "./pages/Subadmin/UserProfile/Security";
// import Refral from "./pages/UserProfile/Refral";
import Doctor from "./pages/Admin/Doctor/Doctor";
import SubAdminDoctor from "./pages/Subadmin/Doctor/Doctor";
import EditDoctor from "./pages/Admin/Doctor/EditDoctor";
import SubadminEditDoctor from "./pages/Subadmin/Doctor/EditDoctor";
import AddDoctor from "./pages/Admin/Doctor/AddDoctor";
import SubadminAddDoctor from "./pages/Subadmin/Doctor/AddDoctor";
import SubadminAddUser from "./pages/Subadmin/User/Adduser";
import SubadminEditUser from "./pages/Subadmin/User/Edituser";
import Welcome from "./pages/Welcome";
import Contact from "./pages/Admin/Contact/Contact";
import SubadminContact from "./pages/Subadmin/Contact/Contact";
import AddPodcast from "./pages/Admin/podcast/AddPodcast";
import UpdatePod from "./pages/Admin/podcast/UpdatePod";
import Research from "./pages/Admin/Research/Research";
import Add from "./pages/Admin/Research/Add";
import Edit from "./pages/Admin/Research/Edit";
import './pages/Admin/Research/Editor.css'
import 'react-quill/dist/quill.snow.css';
import Faq from "./pages/Admin/FAQ/Faq";
import AddFaq from "./pages/Admin/FAQ/Add";
import EditFaq from "./pages/Admin/FAQ/Edit";

const App = () => {
  return (
    <>
      <Switch>
        {/* admin routes */}

        <Route exact path="/" component={Welcome} />
        <Route exact path="/admin/login" component={Login} />
        <Route exact path="/admin/dashbaord" component={Homepage} />
        <Route exact path="/subAdmin" component={CryptoHomePage} />
        <Route exact path="/addsubAdmin" component={AddSubAdmin} />
        <Route exact path="/editsubAdmin/:id" component={EditSumAdmin} />
        <Route exact path="/Practitioner" component={Doctor} />
        <Route exact path="/addPractitioner" component={AddDoctor} />
        <Route exact path="/editPractitioner/:id" component={EditDoctor} />
        <Route exact path="/user" component={User} />
        <Route exact path="/RequestTojion" component={Requestjion} />
        <Route exact path="/Podcast" component={Podcast} />
        <Route exact path="/edituser/:id" component={EditUser} />
        <Route exact path="/adduser" component={AddUser} />
        <Route exact path="/chart" component={ChartPage} />
        <Route exact path="/AddPodcast" component={AddPodcast} />
        <Route exact path="/UpdatePod/:id" component={UpdatePod} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/research" component={Research} />
        <Route exact path="/addresearch" component={Add} />
        <Route exact path="/editresearch/:id" component={Edit} />
        {/* <Route exact path="/refral" component={Refral} /> */}
        <Route exact path="/security" component={Security} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/:query" component={Register} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/addfaq" component={AddFaq} />
        <Route exact path="/editfaq/:id" component={EditFaq} />

        {/* Sub Admin routes */}

        <Route exact path="/subadmin/login" component={SubAdminLogin} />
        <Route exact path="/Subadmin/dashbaord" component={SubadminHome} />
        <Route exact path="/Subadmin/Practitioner" component={SubAdminDoctor} />
        <Route exact path="/subadmin/addPractitioner" component={SubadminAddDoctor} />
        <Route exact path="/subadmin/editPractitioner/:id" component={SubadminEditDoctor} />
        <Route exact path="/Subadmin/user" component={SubAdminUser} />
        <Route exact path="/Subadmin/adduser" component={SubadminAddUser} />
        <Route exact path="/Subadmin/Edituser/:id" component={SubadminEditUser} />
        <Route exact path="/Subadmin/profile" component={SubadminProfile} />
        <Route exact path="/Subadmin/security" component={SubadminSecurity} />
        <Route exact path="/Subadmin/contact" component={SubadminContact} />

        {/* Sub Admin routes */}
      </Switch>
    </>
  );
};
export default App;
