import React from "react";
import Layout from "../../../layout/Index";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useState } from "react";
import BaseUrl from "../../../BaseURL/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgress } from "@material-ui/core";

const AddPodcast = () => {
  const [title, setTitle] = useState();
  const [date, setdate] = useState();
  const [hide, show] = useState(false);
  const [video, setvideo] = useState();
  const [Link, setLink] = useState("");
  const [showVideo, setShowvideo] = useState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [error, setError] = useState("");

  const videohandle = (event) => {
    const file = event.target.files[0];
    const videoURL = URL.createObjectURL(file);
    setShowvideo(videoURL);
    setvideo(file);
    show(true);
  };
  const addapi = () => {
    const data = new FormData();
    data.append("title", title);
    data.append("date", date);
    if (video) {
      data.append("video", video);
    }
    data.append("link", Link);
    console.log(title, video, date);
    const token = localStorage.getItem("accesstoken");
    setLoader(true);

    var config = {
      method: "POST",
      url: `${BaseUrl.baseurl}/admin/podcasts`,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        console.log(response, "api");
        if (response.data.status === true) {
          setLoader(false);
          toast.success("Podcast Add Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          history.push("/Podcast");
        } else {
          setLoader(false);
        }
        console.log(response);
      })
      .catch((errors) => {
        console.log(errors);
        setError(errors?.response?.data?.errors);
        setLoader(false);
      });
  };
  return (
    <Layout>
      <Head title="Add Podcast" />
      <Content>
        <div className="container-fluid card rounded boxsh py-3">
          <div className="row justify-content-center p-3">
            <div className="col-md-6 boxsh ">
              <div className="card  px-2 py-4 rounded-4">
                <h3 className="text-theme text-center py-2 ">Add Podcast</h3>
                <div className="form-group">
                  <label for="exampleInputEmail1">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <p className="text-danger">{error?.title}</p>
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <input
                    type="Date"
                    className="form-control"
                    onChange={(e) => setdate(e.target.value)}
                  />
                  <p className="text-danger">{error?.date}</p>
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">podcast video Link</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Video Link"
                    onChange={(e) => setLink(e.target.value)}
                  />
                  <p className="text-danger">{error?.video}</p>
                </div>
                <p>--------------------------------------------------OR-----------------------------------------------------</p>
                <div className="form-group">
                  <label for="exampleInputEmail1">podcast Upload video</label>
                  <input
                    type="file"
                    accept="video/*"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    onChange={videohandle}
                  />
                  <p className="text-danger">{error?.video}</p>
                </div>
                {hide ? (
                  <div className="form-group">
                    <video
                      src={showVideo}
                      controls
                      width={"100%"}
                      height={"300px"}
                    />
                  </div>
                ) : null}
                <button
                  className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center"
                  onClick={addapi}
                >
                  Add &nbsp;&nbsp;
                  {loader === true ? (
                    <CircularProgress
                      style={{ height: "20px", width: "20px", color: "white" }}
                    />
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AddPodcast;
