const menu = [
  
    {
      icon: "user-check",
      text: "Admin Dashboard",
      link: "/admin/dashbaord",
    },
    {
      icon: "users-fill",
      text: "Sub Admin",
      link: "/subAdmin",
    },
    {
      icon: "user-round",
      text: "Practitioner",
      link: "/Practitioner",
    },
    {
      icon: "user-list-fill",
      text: "User",
      link: "/user",
    },
    {
      icon: "user-round",
      text: "Contact",
      link: "/contact",
    },
    {icon: "signout",
      text: "Request To join",
      link: "/RequestTojion",
    },
    {icon: "video",
    text: "Podcast",
    link: "/Podcast",
  },
    {icon: "search",
    text: "Research",
    link: "/research",
  },
    {icon: "question-alt",
    text: "FAQ",
    link: "/faq",
  },
      

];

export default menu;
