/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Head from "../../../subadminlayout/head/Head";
import Content from "../../../subadminlayout/content/Content";
import Layout from "../../../subadminlayout/Index";
import { Card, Col, Row } from "reactstrap";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { useState } from "react";
import BaseUrl from "../../../BaseURL/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

const Security = ({ sm, updateSm}) => {

    const [modal, setModal] = useState(false);
    const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [Email, setEmail] = useState("");
    const [security, setSecurity] = useState("");
    const [, setLoaders] = useState(false);
    const [loader, setLoader] = useState(false);


    var token = localStorage.getItem("accesstoken");
    const id = localStorage.getItem("id");
  
    const viewData = () => {
      setLoaders(true);
      var config = {
        method: "get",
        url: `${BaseUrl.baseurl}/subadmin/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      axios(config)
        .then((response) => {
          setLoaders(false);
          console.log(response);
          setFName(response?.data?.data?.fname);
        setLName(response?.data?.data?.lname);
        setEmail(response?.data?.data?.email);
          setSecurity(response?.data?.data?.password);
        })
        .catch((error) => {
          setLoaders(false);
          console.log(error);
        });
    };
  
    useEffect(() => {
      viewData();
    }, []);
  
    const updateData = () => {
      setLoader(true);
      const data = new URLSearchParams();
      data.append("fname",fname);
      data.append("lname",lname);
      data.append("email",Email);
      data.append("password", security);
  
      var config = {
        method: "PUT",
        url: `${BaseUrl.baseurl}/subadmin/${id}`,
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      axios(config)
        .then((response) => {
          setLoader(false);
          console.log(response);
          if (response?.data?.status === true) {
            toast.success(response?.data?.response);
            setModal(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          setModal(true);
          console.log(error);
        });
    };
  
  return (
    <Layout>
      <Head title="User List - Profile"></Head>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4" className="text-theme">Security Settings</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Change Password</h6>
                    <p>Set a unique password to protect your account.</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Button   className="btn-theme" onClick={()=>setModal(true)}>Change Password</Button>
                      </li>
                      <li>
                        <em className="text-soft text-date fs-12px">
                          Last changed: <span>Oct 2, 2019</span>
                        </em>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Block>
        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Profile</h5>
              <ul className="nk-nav nav nav-tabs">
                
              </ul>
                <div className="tab-content">
                  <div id="personal">
                    <Row className="gy-4">
                      <Col md="12">
                        <FormGroup>
                          
                          <input
                            type="Password"
                            id="Password"
                            className="form-control"
                            name="New Password"
                            onChange={(e) =>setSecurity(e.target.value)}
                            placeholder="New Password"
                          />
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              className="btn-theme"
                              size="lg"
                              onClick={(ev) => {
                                ev.preventDefault();
                                updateData();
                              }}
                            >
                             Update &nbsp;&nbsp;
                              {loader === true ? (
                                <CircularProgress
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "white",
                                  }}
                                />
                              ) : null}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal(false);
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </Layout>
  );
};

export default Security;
