/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import BaseUrl from "../../../BaseURL/BaseUrl";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Layout from "../../../layout/Index";
import axios from "axios";
import "../../../pages/pages.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import { toast } from "react-toastify";


const EditSumAdmin = () => {


  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, ] = useState("");


  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("accesstoken");

  const editData = () => {
    setLoader(true)
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/admin/subadmins/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false)
        console.log(response);
        const {fname,lname, email,password} = response?.data?.data;
        setEmail(email);
        setFname(fname);
        setLname(lname);
        setPassword(password);
      })
      .catch((error) => {
        setLoader(false)
        console.log(error);
      });
  };

  useEffect(() => {
    editData();
  }, []);



  const addapi = () => {
    setLoader(true);
    try {
      const data = new URLSearchParams();
      data.append("fname", fname);
      data.append("lname", lname);
      data.append("email", email);
      data.append("password", password);

      var config = {
        method: "PUT",
        url: `${BaseUrl.baseurl}/admin/subadmins/${id}`,
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response, "api");
          if (response.data.status === true) {
            setLoader(false);
            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            history.push("/subadmin");
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (errors) {
      setLoader(false);
      console.log(errors, "error");
      toast.error(errors?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  return (
    <>
     <Layout>
        <Head title="Update Sub-Admin" />
        <Content>
          <div className="container-fluid card rounded boxsh py-3">
            <div className="row justify-content-center p-3">
              <div className="col-md-6 boxsh ">
                <div className="card  px-2 py-4 rounded-4">
                  <h3 className="text-theme text-center py-2 ">Update SUB-ADMIN</h3>
                  <div className="form-group">
                    <label for="exampleInputEmail1">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                    <p className="text-danger">{error?.fname}</p>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                    <p className="text-danger">{error?.lname}</p>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="text-danger">{error?.email}</p>
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      // value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-danger">{error?.password}</p>
                  </div>
                  <button
                    className="btn btn-theme w-50 mx-auto d-block d-flex justify-content-center align-items-center"
                    onClick={addapi}
                  >
                    Add &nbsp;&nbsp;
                    {loader === true ? (
                      <CircularProgress style={{ height: "20px", width: "20px", color: "white" }} />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default EditSumAdmin;
